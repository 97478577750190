var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-100 p-0 overflow-hidden"},[_c('b-table',{staticClass:"position-relative",attrs:{"items":_vm.members,"responsive":"","small":_vm.isSmall,"fields":_vm.fields,"busy":_vm.busy,"primary-key":"id","show-empty":"","sort-icon-left":"","empty-text":"No matching records found"},scopedSlots:_vm._u([{key:"cell(fullname)",fn:function(ref){
var item = ref.item;
return [_c('b-media',{staticClass:"d-flex align-items-center ml-25 mt-25 "},[_c('b-link',{staticClass:"text-dark d-flex ml-1",attrs:{"to":_vm.getFormLocation(item)}},[_c('div',[_c('p',{staticClass:"mb-0 ml-1"},[_vm._v(" "+_vm._s(_vm.translateTranslationTable(_vm.currentLocale, item.name))+" ")])])])],1)]}},{key:"cell(description)",fn:function(ref){
var item = ref.item;
return [_c('b-media',{staticClass:"d-flex align-items-center ml-25 mt-25 "},[_c('b-link',{staticClass:"text-dark d-flex ml-1",attrs:{"to":_vm.getFormLocation(item)}},[_c('div',{staticClass:"ml-1"},[(_vm.translateTranslationTable(_vm.currentLocale, item.description))?_c('span',{staticClass:"mb-0",domProps:{"innerHTML":_vm._s(_vm.translateTranslationTable(_vm.currentLocale, item.description))}}):_c('small',{staticClass:"text-muted"},[_vm._v(" --- ")])])])],1)]}},{key:"cell(model)",fn:function(ref){
var item = ref.item;
return [_c('b-link',{staticClass:"text-dark d-flex ml-1",attrs:{"to":_vm.getFormLocation(item)}},[(item.type && _vm.types[item.type])?_c('b-badge',{attrs:{"pill":"","variant":_vm.types[item.type].color}},[_vm._v(" "+_vm._s(_vm.types[item.type].label)+" ")]):_c('span',{staticClass:"align-text-top text-muted text-capitalize mb-0 pl-25 ml-1 mt-50 truncated-text"},[_vm._v(" --- ")]),_c('b-link')],1)]}},{key:"cell(creator)",fn:function(ref){
var item = ref.item;
return [_c('b-media',{staticClass:"d-flex align-items-center"},[_c('b-link',{staticClass:"d-flex ",attrs:{"to":_vm.getCreatorLocation(item)}},[_c('p',{staticClass:"mb-0 ml-1"},[_vm._v(" "+_vm._s(item.createdBy.name)+" "+_vm._s(item.createdBy.surname)+" ")])])],1)]}},{key:"cell(date)",fn:function(ref){
var item = ref.item;
return [_c('b-media',{staticClass:"d-flex align-items-center"},[_c('p',{staticClass:"text-dark mb-0 ml-1"},[_vm._v(" "+_vm._s(new Date(item.createdAt).toLocaleDateString())+" ")])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }