<template>
  <div class="w-100 p-0 overflow-hidden">
    <b-table
      class="position-relative"
      :items="members"
      responsive
      :small="isSmall"
      :fields="fields"
      :busy="busy"
      primary-key="id"
      show-empty
      sort-icon-left
      empty-text="No matching records found"
    >
      <!-- Column: Name -->
      <template #cell(fullname)="{ item }">
        <b-media class="d-flex align-items-center ml-25 mt-25 ">
          <b-link :to="getFormLocation(item)" class="text-dark d-flex ml-1">
            <div>
              <p class="mb-0 ml-1">
                {{ translateTranslationTable(currentLocale, item.name) }}
              </p>
            </div>
          </b-link>
        </b-media>
      </template>
      <template #cell(description)="{ item }">
        <b-media class="d-flex align-items-center ml-25 mt-25 ">
          <b-link :to="getFormLocation(item)" class="text-dark d-flex ml-1">
            <div class="ml-1">
              <span v-if="translateTranslationTable(currentLocale, item.description)" class="mb-0" v-html="translateTranslationTable(currentLocale, item.description)" />
              <small v-else class="text-muted"> --- </small>
            </div>
          </b-link>
        </b-media>
      </template>
      <!-- Column: Model -->
      <template #cell(model)="{ item }">
        <b-link :to="getFormLocation(item)" class="text-dark d-flex ml-1">
          <b-badge 
            v-if="item.type && types[item.type]" 
            pill 
            :variant="types[item.type].color"
          >
            {{ types[item.type].label }}
          </b-badge>
          <span 
            v-else 
            class="align-text-top text-muted text-capitalize mb-0 pl-25 ml-1 mt-50 truncated-text">
            ---
          </span>
          <b-link />
        </b-link>
      </template>
      <template #cell(creator)="{ item }">
        <b-media class="d-flex align-items-center">
          <b-link :to="getCreatorLocation(item)" class="d-flex ">
            <p class="mb-0 ml-1">
              {{ item.createdBy.name }} {{ item.createdBy.surname }}
            </p>
          </b-link>
        </b-media>
      </template>
      <template #cell(date)="{ item }">
        <b-media class="d-flex align-items-center">
          <p class="text-dark mb-0 ml-1">
            {{ new Date(item.createdAt).toLocaleDateString() }}
          </p>
        </b-media>
      </template>
    </b-table>
  </div>
</template>

<script>
import { getImageResource } from '@/@core/utils/image-utils';
import MembersPlaceholder from '@/assets/images/placeholders/light/members.svg';
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
import { validatorEmailValidator } from '@/@core/utils/validations/validators';
import { translateTranslationTable } from '@/@core/libs/i18n/utils';

export default {
  name: 'FormsTable',

  mixins: [ToastNotificationsMixin],
  props: {
    members: {
      type: [Array, Function],
      default: () => [],
    },
    busy: Boolean,
  },
  data() {
    return {
      isSmall: false,
      isModalOpened: false,
      isUnshareModalVisible: false,
    };
  },
  computed: {
    fields() {
      const fields = [
        {
          key: 'fullname',
          label: this.$t('backoffice.discounts.name'),
          sortable: true,
        },
        {
          key: 'description',
          label: this.$t('backoffice.discounts.description'),
          sortable: false,
        },
        {
          key: 'model',
          label: this.$t('settings.mainData.type'),
          sortable: true,
        },
        {
          key: 'creator',
          label: this.$t('contacts.creator'),
          sortable: false,
          thClass: 'pl-2',
        },
        {
          key: 'date',
          label: this.$t('contacts.creation-date'),
          sortable: false,
          thClass: 'pl-2',
        },
      ];
      return fields;
    },
    membersPlaceholder() {
      return MembersPlaceholder;
    },
    currentLocale() {
      return this.$store.state.locale.currentLocale;
    },
    community() {
      return this.$store.getters.currentCollective;
    },
    types() {
      return {
        'form': { 
          label: this.$t('backoffice.communication.forms.types.form'),
          color: 'success'
        },
        'survey': { 
          label: this.$t('backoffice.communication.forms.types.survey'),
          color: 'info'
        },
        'exam': { 
          label: this.$t('backoffice.communication.forms.types.exam'),
          color: 'warning'
        },
        'activity': { 
          label: this.$t('backoffice.communication.forms.types.activity'),
          color: 'primary'
        },
        'vote': { 
          label: this.$t('backoffice.communication.forms.types.vote'),
          color: 'secondary'
        }
      };
    },
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    deleteItem(item) {
      this.itemToDelete = item.item;
      this.isUnshareModalVisible = true;
    },
    async handleRemoveItem(response) {
      if (response === true) {
        try {
          await this.$store.dispatch('postItem', {
            type: 'coupons/delete',
            requestConfig: {
              key: this.itemToDelete.key,
            },
          });
          this.$emit('fetchData');
          this.notifySuccess(this.$t('success-message.general-success-delete'));
        } catch {
          this.notifyError(this.$t('error-message.general-error'));
        }
      }
    },
    getColor(type) {
      switch(type){
        case 'survey':
          return 'warning';
        case 'exam':
          return 'info';
        case 'activity':
          return 'primary';
        default:
          return 'success';
      }
    },
    getText(type) {
      return 
      if (type === 'survey') {
        return this.$t('backoffice.communication.forms.types.survey');
      }
      return this.$t('backoffice.communication.forms.types.form');
    },
    handleResize() {
      this.isSmall = window.innerWidth < 400;
    },
    getImageResource,
    validatorEmailValidator,
    getDate(item) {
      const d = new Date(item);
      return d.toLocaleString('en-GB');
    },
    translateTranslationTable,
    share(item) {
      this.isModalOpened = true;
      this.currentDiscount = item.item;
    },
    getCreatorLocation(item) {
      return {
        name: 'member-details',
        params: {
          username: item.createdBy.username,
          communityId: this.$route.params.communityId,
        },
      };
    },
    getFormLocation(form) {
      return {
        name: 'forms-bo-details',
        params: { id: form.key, communityId: this.$route.params.communityId },
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.truncated-text {
  white-space: nowrap;
  flex: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 250px;
  min-width: 0;
}
.check-button {
  cursor: pointer !important;
}
</style>
