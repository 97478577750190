<template>
  <backoffice-container>
    <b-card style="min-height:80vh">
      <div>
        <div class="d-flex justify-content-between flex-wrap my-1">
          <h4 class="mb-1">
            {{ $t('backoffice.communication.forms.title') }}
          </h4>
          <b-button
            variant="primary"
            class="font-weight-bold"
            size="sm"
            @click="openModal"
          >
            {{ $t('backoffice.communication.forms.new') }}
          </b-button>
        </div>
        <div v-if="forms.length > 0">
          <base-pagination-container
            class="members-list-container"
            :total="forms.length"
            :loading="isLoading"
            :items="forms"
            :per-page="perPage"
            :load-first-page="true"
            hide-pagination
            :search-placeholder="$t('backoffice.discounts.title')"
            @load-page="handleLoadPage"
          >
            <template #default="{ items, loading }">
              <forms-table
                :members="items"
                :busy="loading"
                :discount-type="form.discountType"
                @editDiscount="openModal"
                @fetchData="getInitialData"
              />
            </template>
          </base-pagination-container>
        </div>
        <div v-else class="horizontal-placeholder d-flex flex-column flex-wrap justify-content-center align-items-center">
          <img :src="placeholder">
          <p class="text-primary">
            {{ $t('backoffice.feedback.placeholder-text', { item: $t('backoffice.communication.forms.title') }) }}
          </p>
        </div>
      </div>
    </b-card>
    <modal-create-form :form="form" />
  </backoffice-container>
</template>

<script>
import BasePaginationContainer from '@core/components/containers/base/BasePaginationContainer.vue';
import CardWidgetMixin from '@core/mixins/widgets/CardWidgetMixin';
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
import FormsTable from '@/backoffice/views/forms/components/FormsTable.vue';
import BackofficeContainer from '@/backoffice/components/BackofficeContainer.vue';
import Placeholder from '@/assets/images/placeholders/light/general-placeholder.svg';
import ModalCreateForm from '@/backoffice/views/forms/components/ModalCreateForm.vue';

export default {
  name: 'FormsBo',
  components: {
    BasePaginationContainer,
    FormsTable,
    BackofficeContainer,
    ModalCreateForm,

  },
  mixins: [CardWidgetMixin, ToastNotificationsMixin],
  props: {
    perPage: {
      type: Number,
      default: 20,
    },
  },
  data() {
    return {
      isLoading: false,
      page: 1,
      form: {
        name: {
          ca: '',
          es: '',
          en: '',
        },
        description: {
          ca: '',
          es: '',
          en: '',
        },
      },
    };
  },
  computed: {
    forms() {
      return this.$store.getters.forms.unpaginated;
    },
    communitySlug() {
      return this.$route.params.communityId;
    },
    placeholder() {
      return Placeholder;
    },
    community() {
      return this.$store.getters.currentCollective;
    },
  },
  async created() {
    this.updateBreadcrumbs();
    await this.getInitialData();
  },
  methods: {
    openModal() {
      this.form = {
        name: {
          ca: '',
          es: '',
          en: '',
        },
        description: {
          ca: '',
          es: '',
          en: '',
        },
      };
      this.$bvModal.show('forms-bo-modal');
    },
    async getInitialData() {
      try {
        this.isLoading = true;
        await this.$store.dispatch('getItems', {
          itemType: 'forms',
          forceAPICall: true,
          perPage: 100,
        });
        this.isLoading = false;
      } catch {
        this.notifyError(this.$t('error-message.load-failed'));
      }
    },
    async handleLoadPage() {
      this.getInitialData();
    },
    updateBreadcrumbs() {
      const breadcrumbs = [

      ];
      this.$store.commit('app/SET_BREADCRUMBS', breadcrumbs);
    },
  },
};
</script>

<style lang="scss" scoped>
.members-list-container {
  overflow: hidden;
  flex: 1;
}
.toggle-filters-button {
  outline: none;
  box-shadow: none;
  min-width: 25px;
  margin-left: 10px;
}
</style>
