<template>
  <b-modal
    id="forms-bo-modal"
    :ok-disabled="!hasName"
    :ok-title="$t('form.actions.save')"
    ok-only
    centered
    size="lg"
    @ok="createForm()"
  >
    <template #modal-header>
      <language-selector-header 
        :title="form.key ? $t('backoffice.communication.forms.edit') : $t('backoffice.communication.forms.new')" 
        @closeModal="closeModal" 
        @selectLanguage="(language)=>selectedLanguage = language" 
      />
    </template>
    <b-form>
      <b-row>
        <!--  title -->
        <b-col cols="12">
          <b-form-group>
            <label for="name" class="font-weight-bold">
              {{ $t('forms.title') }}*
            </label>
            <b-form-input
              id="name"
              v-model="form.name[selectedLanguage]"
              type="text"
            />
          </b-form-group>
        </b-col>
        <!-- type -->
        <b-col cols="12" md="12">
          <b-form-group>
            <label for="type" class="font-weight-bold">
              {{ $t('settings.mainData.type') }}*
            </label>
            <v-select
              id="type"
              v-model="typeSelected"
              :clearable="false"
              :reduce="(type) => type.code"
              :options="types"
              label="label"
            />
          </b-form-group>
        </b-col>
        <!-- maxScore -->
        <b-col cols="12" md="6">
          <b-form-group>
            <label for="score" class="font-weight-bold">
              {{ $t('forms.max-score') }}
            </label>
            <b-form-input
              id="score"
              v-model="form.maxScore"
              type="number"
            />
          </b-form-group>
        </b-col>
        <!-- scoreToPass -->
        <b-col cols="12" md="6">
          <b-form-group>
            <label for="score" class="font-weight-bold">
              {{ $t('forms.score-to-pass') }}
            </label>
            <b-form-input
              id="score"
              v-model="form.scoreToPass"
              type="number"
            />
          </b-form-group>
        </b-col>
        <!-- description -->
        <b-col cols="12">
          <b-form-group>
            <label for="description" class="font-weight-bold">{{ $t('backoffice.discounts.description') }}</label>
            <quill-editor
              id="description"
              v-model="form.description[selectedLanguage]"
              rows="12"
              class="quillE"
              :placeholder="$t('form.select-placeholder')"
            />
          </b-form-group>
        </b-col>
        <!-- image -->
        <b-col cols="12">
          <b-form-group 
            :label="$t('backoffice.onboarding-form.form.image')" 
            label-for="image" 
            class="d-inline-block w-100"
          >
            <!--             <file-preview
              v-if="!form.imageURL && !image"
              :src="joinCommunityImage"
              :placeholder-image="joinCommunityImage"
              mime-type="image"
              style="height:300px"
            /> -->
            <file-preview
              v-if="form.imageURL"
              :src="getImageResource(form.imageURL)"
              mime-type="image"
              cancelable
              @cancel="form.imageURL = null"
            />
            <file-upload
              v-else
              v-model="image"
              type="image"
              cancelable
              :placeholder="$t('form-create-item.image-button')"
              :drop-placeholder="$t('form-create-item.drop-placeholder')"
              @cancel="image = null"
            />
          </b-form-group>
        </b-col>
        <!-- StartDate -->
        <b-col cols="12" md="6">
          <b-form-group>
            <label for="startDate" class="font-weight-bold">
              {{ $t("backoffice.settings.start-date") }}
            </label>
            <VueCtkDateTimePicker
              id="startDate"
              v-model="form.startDate"
              :locale="locale"
              format="YYYY-MM-DD HH:mm"
              :label="$t('datepicker.select-label')"
              color="var(--primary)"
              no-button-now
              button-color="var(--primary)"
              class="date-picker"
            />
          </b-form-group>
        </b-col>
        <!-- EndDate -->
        <b-col cols="12" md="6">
          <b-form-group>
            <label for="endDate" class="font-weight-bold">
              {{ $t("backoffice.settings.end-date") }}
            </label>
            <VueCtkDateTimePicker
              id="endDate"
              v-model="form.endDate"
              :locale="locale"
              :behaviour="{
                time: {
                  nearestIfDisabled: false,
                },
              }"
              format="YYYY-MM-DD HH:mm"
              :label="$t('datepicker.select-label')"
              color="var(--primary)"
              no-button-now
              button-color="var(--primary)"
              :min-date="form.startDate"
            />
          </b-form-group>
        </b-col>
        <!-- isCompletable -->
        <b-col cols="12" md="6">
          <b-form-checkbox
            v-model="form.isCompletable"
            name="check-button"
            switch
          >
            {{ $t("media.form.is-completable") }}
          </b-form-checkbox>
        </b-col>
        <!-- completable points to give when passed -->
        <b-col v-if="form.isCompletable" cols="12" md="6">
          <label for="pointsPass" class="font-weight-bold">
            {{ $t('backoffice.communication.forms.points-when-passed') }}
          </label>
          <b-form-input
            id="pointsPass"
            v-model="form.pointsWhenPassed"
            type="number"
            placeholder="Default: your score"
          />
        </b-col>
      </b-row>
    </b-form>
  </b-modal>
</template>

<script>

import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
import LanguageSelectorHeader from '@core/components/modal/LanguageSelectorHeader.vue';
import vSelect from 'vue-select';
import { quillEditor } from 'vue-quill-editor';
import FileUpload from '@core/components/files/FileUpload.vue';
import FilePreview from '@core/components/files/FilePreview.vue';
import { getImageResource } from '@/@core/utils/image-utils';

export default {
  name: 'ModalCreateFrom',
  components: {
    LanguageSelectorHeader,
    vSelect,
    quillEditor,
    FilePreview,
    FileUpload,
  },
  mixins: [ToastNotificationsMixin],
  props: {
    form: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      typeSelected: '',
      selectedLanguage: '',
      image: null,
    };
  },
  computed: {
    types() {
      return [
        { label: this.$t('backoffice.communication.forms.types.form'), code: 'form' },
        { label: this.$t('backoffice.communication.forms.types.survey'), code: 'survey' },
        { label: this.$t('backoffice.communication.forms.types.exam'), code: 'exam' },
        { label: this.$t('backoffice.communication.forms.types.activity'), code: 'activity' },
      ];
    },
    locale() {
      return this.$store.state.locale.currentLocale;
    },
    hasName() {
      return Object.values(this.form?.name).some((item) => item != '');
    },
  },
  created() {
    this.selectedLanguage = this.locale;
    this.typeSelected = this.form.key ? this.form.type : this.types[0].code;
  },
  methods: {
    async createForm() {
      const file = {};
      const itemDelete = {};
      this.image ? file.imageURL = this.image : (file.imageURL = null, itemDelete.deleteImageURL = true);

      const requestConfig = {
        name: this.form.name,
        description: this.form.description,
        startDate: this.form.startDate,
        endDate: this.form.endDate,
        isCompletable: this.form.isCompletable,
        scoreToPass: this.form.scoreToPass,
        maxScore: this.form.maxScore,
        pointsWhenPassed: this.form.pointsWhenPassed,
        type: this.typeSelected,
        ...itemDelete,
      };
      try {
        let response = null;
        if (this.form.key) {
          //Edit
          requestConfig.key = this.form.key;
          response = await this.$store.dispatch('editItem', {
            item: {
              itemType: 'forms',
              requestConfig,
            },
            file,
          });
        } else {
          //Create 
          response = await this.$store.dispatch('createItem', {
            item: {
              itemType: 'forms',
              orderByDate: 1,
              requestConfig,
            },
            file,
            // file: this.image,
          });
          //console.log('response', response);
        }
        if(response.status === 200){
          this.notifySuccess(this.$t('success-message.general-success-action'));
        }else{
          //console.log('bad response:', response.statusText);
          const errorText = this.$t('error-message.general-error') + ' \n Reason: ' + response.statusText;
          this.notifyError(errorText);
        }
      } catch {
        this.notifyError(this.$t('error-message.general-error'));
      }
    },
    getImageResource(image) {
      return getImageResource(image);
    },
    closeModal() {
      this.$bvModal.hide('forms-bo-modal');
    },
  },

};
</script>

<style>

</style>
